import { types } from "../../types/types";

const initialState = {
    registers: [],
    endPoint: null,
    filters: {},
    options: {},
    count: 0,
    page: 1,
    limit: 25,
    massiveActionsRow: [],
};

const handleNestedState = (keys, initialData, action) => {
    const nested = (data, index = 0) => {
        if (index === keys.length - 1) {
            if (action.payload.removeRepeater) {
                return {
                    ...data,
                    [keys[index]]: [...data[keys[index]].filter((f, i) => i !== action.payload.position)],
                };
            } else if (action.payload.addRepeater) {
                return {
                    ...data,
                    [keys[index]]: [...data[keys[index]], action.payload.value],
                };
            }
        }
        if (index === keys.length) {
            if (action.payload.trim) {
                return {
                    ...data,
                    [action.payload.name]: [
                        ...data[action.payload.name].filter((f) => !!!action.payload.value.some((a) => a === f)),
                    ],
                };
            }
            if (action.payload.merge) {
                return {
                    ...data,
                    [action.payload.name]: [...data[action.payload.name], ...action.payload.value],
                };
            }
            if (action.payload.enumeration) return { ...data, [action.payload.name]: action.payload.value };
            if (action.payload.customMTM) {
                return [
                    ...action.payload.value.map((a) => ({
                        id: a.value,
                        name: a.label,
                    })),
                ];
            }
            return { ...data, [action.payload.name]: action.payload.value };
        }
        if (index !== keys.length - 1 && data[keys[index]] instanceof Array) {
            data[keys[index]][keys[index + 1]] = nested(data[keys[index]][keys[index + 1]], index + 2);
            return { ...data, [keys[index]]: [...data[keys[index]]] };
        }
        return { ...data, [keys[index]]: nested(data[keys[index]], ++index) };
    };
    return nested(initialData);
};

const list = (state = initialState, action) => {
    switch (action.type) {
        case types.editCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) };
        case types.addCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) };
        case types.update:
            return {
                ...state,
                registers: [...state.registers, ...action.payload.data],
                count: action.payload.count,
            };

        case types.load:
            // If server returns an error, you can handle it here
            if (!action.payload.data) action.payload.data = [];
            return {
                ...state,
                endPoint: action.payload.endPoint,
                count: action.payload.count,
                registers: [...action.payload.data],
            };
        case types.delete:
            return {
                ...state,
                registers: state.registers.filter((reg) => reg.id !== action.payload),
            };
        case types.changemassiveActionss:
            if (action.payload.type !== "list") {
                if (action.payload.checked === true) {
                    return {
                        ...state,
                        [action.payload.name]: [...state[action.payload.name], action.payload.value],
                    };
                } else {
                    return {
                        ...state,
                        [action.payload.name]: [
                            ...state[action.payload.name].filter((s) => s !== action.payload.value),
                        ],
                    };
                }
            } else {
                return {
                    ...state,
                    [action.payload.name]: action.payload.value,
                };
            }

        case types.cleaningAll:
            return initialState;

        default:
            return state;
    }
};

export default list;
