export const types = {
    load: "[Load] Loading registers from table",
    delete: "[Delete] Delete register",
    cleaningAll: "[Cleaning] Cleaning register from redux",
    changemassiveActionss: "[Change] Changing massive uploads",
    addCollectionRegister: "[Add] Adding collecion register",
    editCollectionRegister: "[Change] Changing collecion register",

    update: "[Update] Update table records",
};
