import { getDateTime } from "../../../utility/helpers/DateTime/formatDate";
import { planningTypes } from "../../types/planning/types";

export const initialState = {
    past: [],
    present: {
        filters: {
            pickup: {
                isItinerant: true,
                isNonItinerant: true,
                isProgrammed: true,
                isNonProgrammed: true,
                "rec-enrutados": true,
                "rec-no-enrutados": true,
            },
            enterprise: {
                enterprises: [],
            },
            vehicle: {
                hasNotDriver: false,
                vehicleTypes: [],
            },
            driver: {
                drivers: [],
            },
            garbage: {
                garbageTypes: [],
            },
            route: {
                isItinerant: true,
                isNonItinerant: true,
                isProgrammed: true,
                isNonProgrammed: true,
                isConflictive: true,
                isNonConflictive: true,
                sdrComplete: true,
                sdrPartial: true,
                sdrVoid: true,
            },
        },
        planification: [],
        manipulator: {
            pickups: {
                preSelectList: [],
                selected: null,
                check: [],
            },
            routes: {
                preSelectList: [],
                selected: null,
                check: [],
            },
        },
        kanban: {
            date: getDateTime(new Date()),
            mode: "day",
            // ['week': true, 'day': false, 'map', 'list']
        },
        vehicles: [],
        drivers: [],
    },
    future: [],
};

const handleNestedState = (keys, initialData, action) => {
    const nested = (data, index = 0) => {
        if (index === keys.length) {
            if (action.payload.trim) {
                return {
                    ...data,
                    [action.payload.name]: [
                        ...data[action.payload.name].filter((f) => !!!action.payload.value.some((a) => a === f)),
                    ],
                };
            }
            if (action.payload.merge) {
                return {
                    ...data,
                    [action.payload.name]: [...data[action.payload.name], ...action.payload.value],
                };
            }
            if (action.payload.enumeration) return { ...data, [action.payload.name]: action.payload.value };
            if (action.payload.customMTM) {
                return [
                    ...action.payload.value.map((a) => ({
                        id: a.value,
                        name: a.label,
                    })),
                ];
            }
            return { ...data, [action.payload.name]: action.payload.value };
        }

        if (index !== keys.length - 1 && data[keys[index]] instanceof Array) {
            data[keys[index]][keys[index + 1]] = nested(data[keys[index]][keys[index + 1]], index + 2);
            return { ...data, [keys[index]]: [...data[keys[index]]] };
        } else if (action.payload.removeRepeater) {
            return {
                ...data,
                [keys[index]]: [...data[keys[index]].filter((f, i) => i !== action.payload.position)],
            };
        } else if (action.payload.addRepeater) {
            return {
                ...data,
                [keys[index]]: [...data[keys[index]], action.payload.value],
            };
        }
        return { ...data, [keys[index]]: nested(data[keys[index]], ++index) };
    };
    return nested(initialData);
};

const planning = (state = initialState, action) => {
    const { past, present, future } = state;
    let newPresent = null;

    switch (action.type) {
        case "UNDO":
            const previous = past[past.length - 1];
            const newPast = past.slice(0, past.length - 1);
            if (previous === undefined) return state;
            return {
                past: newPast,
                present: previous,
                future: [present, ...future],
            };
        case "REDO":
            const next = future[0];
            const newFuture = future.slice(1);
            if (next === undefined) return state;
            return {
                past: [...past, present],
                present: next,
                future: newFuture,
            };

        case planningTypes.changeCollection:
            newPresent = {
                ...handleNestedState(action.collection, present, action),
            };
            if (present === newPresent) return state;
            //TODO: ADD present to past
            return {
                past: [...past],
                present: newPresent,
                future: [...future],
            };
        case planningTypes.reset:
            return { ...initialState };
        default:
            return state;
    }
};
export default planning;
