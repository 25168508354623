// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "@auth/redux/reducer";
import navbar from "./navbar";
import layout from "./layout";

import list from "./list";
import validator from "./validator";

import form from "./form";
import select from "./selects";
import alert from "./alert";
import planning from "./planning";

const rootReducer = combineReducers({
    auth,
    navbar,
    layout,
    list,
    form,
    select,
    alert,
    validator,
    planning,
});

export default rootReducer;
