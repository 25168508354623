export const getDateTime = (date = null, separator = "-", showTime = false, reverse = false) => {
    if (date === null) return;
    date = date ? new Date(date) : new Date();
    const array = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    const format = separator === "-" ? array : !reverse ? array.reverse() : array;

    const fullDay = format.map((n) => (n < 10 ? `0${n}` : n)).join(separator);
    const time = [date.getHours(), date.getMinutes(), date.getSeconds()].map((n) => (n < 10 ? `0${n}` : n)).join(":");
    return `${fullDay}${showTime ? ` ${time}` : ""}`;
};
