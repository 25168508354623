import { formValidatorTypes } from "../../types/validator";

// ** Initial State
const initialState = {
    schema: {},
    errors: [],
};

const validator = (state = initialState, action) => {
    switch (action.type) {
        case formValidatorTypes.setSchema:
            return {
                ...state,
                schema: {
                    ...action.payload,
                },
            };

        case formValidatorTypes.setErrors:
            return {
                ...state,
                errors: [...action.payload],
            };
        case formValidatorTypes.removeError:
            return {
                ...state,
                errors: [...action.payload],
            };

        default:
            return state;
    }
};

export default validator;
